define(function(require, exports, module) {
    var $ = require("jquery");

    return function() {
        $(".login-form").submit(function(e) {
            localStorage.removeItem("vesselFilters");
        });

        $(".easy-login").click(function(e) {
            localStorage.removeItem("vesselFilters");
        });
    };
});
